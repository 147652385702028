import React from 'react';
import { withRouter } from "react-router";
import MetabaseDashboardPage from 'components/MetabaseDashboardPage';

const RelOpsEst = () => {
  return (
    <MetabaseDashboardPage
      fetchUrl="relatorios/opsEstabelecimentos"
      title="Operações lojas"
    />
  );
}

export default withRouter(RelOpsEst);