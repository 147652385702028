import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from 'react-router';

const MetabaseDashboardPage = ({fetchUrl, title}) => {
  const [ iframeUrl, setIframeUrl ] = useState(null);

  useEffect(() => {
    fetchDashboard();
  }, []);

  function fetchDashboard() {
    Api.get(fetchUrl).then(rps => {
      setIframeUrl(rps.data.iframeUrl)
    })
  }

  return (
    <>
      <div>
        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatório</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">{title}</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center" />
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <iframe
              className="w-100"
              title='metabase_iframe'
              src={iframeUrl}
              frameBorder={0}
              height={1000}
              allowTransparency
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(MetabaseDashboardPage);