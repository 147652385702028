import React from 'react';
import { withRouter } from 'react-router';
import MetabaseDashboardPage from 'components/MetabaseDashboardPage';

const RelOpsKpi = () => {
  return (
    <MetabaseDashboardPage
      fetchUrl="relatorios/opsKpis"
      title="Operações KPIs"
    />
  );
}

export default withRouter(RelOpsKpi);